import { css } from '@emotion/react' 
// import React from 'react'
import { Component, memo } from 'react';
// import PropTypes from 'prop-types';
import loadable from "@loadable/component"
import { 
    Container,
    Typography,
    Grid,
} from '@material-ui/core';
import namesCompany from '../../helpers/NameCompany'
// import Img from "gatsby-image"

// import Hero from '../../../components/img/Hero.compilable'
// import SimuladorCard from './SimuladorCard'
// import CarouselCard from '../../carousel/CarouselCard'
// const Hero = loadable(() => import('../../img/Hero.compilable'))
const SimuladorCard = loadable(() => import('../pages/SimuladorCard'))
const CarouselCard = loadable(() => import('../carousel/CarouselCard'))
// import Mujer from '../../../components/img/Mujer.compilable'

// const imgHeight = 0;
// const mujerContainerCss = css`
//     position: absolute;
//     top: -10px;
//     right: -15px;
// `

// const mujerImgCss = css`
//     height: ${`${imgHeight}px`};
// `

// const containerCss = css`
//     position: relative;
// `
// const simuladorCss = css`
//     padding-top: ${`${imgHeight-(imgHeight/1.5)}px`};
//     position: relative;
//     z-index: 1;
// `
// const sloganCss = css`
//     /* position: relative;
//     z-index: 1;
//     left: 20px;
//     top: 20px; */

// `

const simuladorCss = css`
    margin-top: 10px;
`
class Simulador extends Component {
    // constructor(props){
    //     super(props)
    // // console.log("pageContext s",this.props.pageContext);
    // }
    render() {
        // const {imagenes} = this.props.pageContext
        return (
            <Container>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={6}>
                    <div>
                        <Typography color="primary" variant="h6" align="center">
                            {namesCompany(this.props.pageContext.company).slogan}
                        </Typography>
                    {/* <div css={mujerContainerCss}>
                        <div css={mujerImgCss}>
                            <Hero company={this.props.pageContext.company} />
                        </div>
                    </div> */}
                    <div css={simuladorCss}>
                        <SimuladorCard pageContext={this.props.pageContext} />
                    </div>
                    <br/>
                    <Typography variant="body2">
                        <sup>*</sup>Esto es un simulador, las condiciones y montos otorgados pueden variar conforme a la evaluación de tu perfil.
                    </Typography>
                </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CarouselCard company={this.props.pageContext.company} />
                    </Grid>
                </Grid>

            </Container>
        );
    }
}

// Simulador.propTypes = {

// };

export default memo(Simulador);