import React, { Component } from "react"
import { css } from '@emotion/react'

// FINANCIERA COMPONENTS
import { CardElevation } from '@findep/mf-landings-core'
//MATERIAL UI COMPONENTS 
import { Box, Container } from "@material-ui/core"
import Typography from '@material-ui/core/Typography';

const cmpTxt1 = css` 
  text-align: center;       
  font-size: 20px;    
 `
const cmpTxt2 = css` 
  text-align: left;       
  font-size: 16px;    
 `
export default class CardBenefits extends Component {
    render() {
        return (
            <Container>
                <CardElevation>
                    <Box component="span" m={1} style={{ textAlign: "center" }}>
                        <Typography component='h1' css={cmpTxt1} color='primary'>
                            <strong>Nuestros Beneficios</strong>
                        </Typography>
                        <br />
                        <ul css={cmpTxt2}>
                            <li>Tus pagos son fijos durante toda la vida de tu crédito.</li>
                            <li>No hay penalización por pagos anticipados.</li>
                            <li>La comodidad para hacer tus pagos y acceso a tu cuenta las 24 horas del día desde donde te encuentres a través de nuestra APP móvil.</li>
                        </ul>

                    </Box>
                </CardElevation>
            </Container>
        )
    }

}